import { FelaCSS, alpha, animationTime, colorParse, colors, spacing } from '@bridebook/ui';
import { IModalProps } from './';

export interface ITransitionState {
  entering: FelaCSS;
  entered: FelaCSS;
  exited?: FelaCSS;
}

interface IStyles {
  wrapper: FelaCSS;
  background: FelaCSS;
  backgroundTransition: ITransitionState;
  contentContainer: FelaCSS;
  contentContainerTransition: ITransitionState;
  clickableBg: FelaCSS;
}

interface IProps {
  maxWidth: IModalProps['maxWidth'];
  backgroundColor: IModalProps['backgroundColor'];
  fullContentHeight: IModalProps['fullContentHeight'];
  alignTop: IModalProps['alignTop'];
  drawer: IModalProps['drawer'];
  instant: IModalProps['instant'];
  scrollableContent: IModalProps['scrollableContent'];
  roundedBorders: IModalProps['roundedBorders'];
}

const styles = ({
  maxWidth,
  backgroundColor,
  fullContentHeight,
  alignTop,
  drawer,
  instant,
  scrollableContent,
  roundedBorders,
}: IProps): IStyles => {
  const speed = instant ? 0 : animationTime.fast;

  const fixedBg: FelaCSS = {
    position: 'fixed',
    top: '0',
    bottom: '0',
    left: '0',
    right: '0',
  };

  return {
    wrapper: {
      display: 'flex',
      position: 'relative',
      overflowX: drawer ? 'hidden' : undefined,
      zIndex: 3001,
      alignItems: drawer ? 'flex-end' : 'center',
      justifyContent: alignTop || drawer ? 'flex-start' : 'center',
      left: '0',
      top: '0',
      width: '100%',
      height: scrollableContent ? '100%' : undefined,
      minHeight: scrollableContent ? undefined : '100%',

      maxWidthTabletSm: {
        display: fullContentHeight ? 'block' : 'flex',
      },
    },

    background: {
      ...fixedBg,
      backgroundColor: backgroundColor ? colorParse(backgroundColor) : alpha(colors.space, 90),
      opacity: 0,
      transition: `
        opacity ${speed}ms ease-in-out
      `,
    },

    backgroundTransition: {
      entering: { opacity: 0 },

      entered: { opacity: 1 },
    },

    contentContainer: {
      maxWidth: maxWidth || '860px',
      width: '100%',
      height: scrollableContent ? '100%' : undefined,
      minHeight: scrollableContent ? undefined : drawer ? '100%' : 'auto',
      marginTop: '0',
      marginBottom: '0',
      marginLeft: 'auto',
      marginRight: drawer ? undefined : 'auto',
      opacity: drawer ? 1 : 0,
      transform: drawer ? 'translate3d(100%, 0, 0)' : 'translate3d(0, -50px, 0)',
      transition: `opacity ${speed}ms ease-in-out, transform ${speed}ms ease-in-out`,
      ...(roundedBorders && {
        borderRadius: spacing[4],
        overflow: scrollableContent ? 'scroll' : 'hidden',
        maxWidthMobile: {
          borderRadius: 'unset',
        },
      }),
    },

    contentContainerTransition: {
      entering: {
        opacity: 0,
        transform: drawer ? 'translate3d(100%, 0, 0)' : 'translate3d(0, -50px, 0)',
      },

      entered: {
        opacity: 1,
        transform: 'translate3d(0, 0, 0)',
      },
    },

    clickableBg: {
      ...fixedBg,
    },
  };
};

export default styles;
